import React from 'react'

//import styles
import ProjectGridStyles from './ProjectGridStyles.module.css'

export default ({images}) => (
    <div className={ProjectGridStyles.grid}>
        {images.map((src) => (
            <div className={ProjectGridStyles.image} key={src}>
                <img src={src} alt='' />
            </div>
        ))}
    </div>
)