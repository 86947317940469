import React from 'react'

//import components
import Project from '../components/Project'
import FullWidthImage from '../components/FullWidthImage'
import ImageWithColorBackground from '../components/ImageWithColorBackground'
import ProjectGrid from '../components/ProjectGrid'
import NextSections from '../components/NextSections'

//import images
import phoneMockup from '../images/online-shopping-infographic/infographic-1.jpg'
import infographicFull from '../images/online-shopping-infographic/infographic-full.jpg'
import gridImage1 from '../images/online-shopping-infographic/onlineShopping-grid-1.jpg'
import gridImage2 from '../images/online-shopping-infographic/onlineShopping-grid-2.jpg'
import gridImage3 from '../images/online-shopping-infographic/onlineShopping-grid-3.jpg'
import gridImage4 from '../images/online-shopping-infographic/onlineShopping-grid-4.jpg'

export default () => (
    <Project
        title={'online shopping infographic'}
        date={'2017'}
        position={'Student Project'}
        employer={'Rider University'}
        brief={[
            'This infographic was a group project in which I served as Project Manager. The brief required that each design team research a topic that would factually depict the positives and negatives of the subject. I was responsible for creating a workflow and layout as well as contributing to the creation of many of the graphics.',
            'I chose a triadic color pallet to make it easier to read, creating an inviting tone. Elements such as the mouse divider and addition and subtraction signs were used to reinforce the comparative message. The graphic was created for web and therefore sized appropriately but due to the vector nature of the piece, could also be resized for print.'
        ]}
        skills={[
            'Data Collection & Interpretation',
            'Illustration & Layout Design',
            'Project Management'
        ]}
    >
        <FullWidthImage src={phoneMockup} alt={'Mockup of infographic on a phone in hand'}/>  
        <ImageWithColorBackground src={infographicFull} color={'#D6CDE5'} alt={'Online Shopping Infographic'}/>
        <ProjectGrid images={[
            gridImage1,
            gridImage2,
            gridImage3,
            gridImage4
        ]}/>
        <NextSections
            previous={'instagram-graphics'}
            next={'alloy-chemical-presentation'}
        />
    </Project>
)