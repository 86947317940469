import React from 'react'

//import styles
import ImageWithColorBackgroundStyles from './ImageWithColorBackground.module.css'

//import components
import Padding from './Padding'

const ImageWithColorBackground = ({src, color, alt, size=1000, shadow=false}) => {
    let applyShadow
    if (shadow){
        applyShadow = '5px 5px 6px rgba(0,0,0,.2)'
    }
    return (
        <div
            className={ImageWithColorBackgroundStyles.container}
            style={{background:color}}
        >
            <Padding />
            <img
                src={src}
                alt={alt}
                style={{
                    width: '80%',
                    maxWidth: `${size}px`,
                    boxShadow: applyShadow
                }}
            />
            <Padding />
        </div>
    )
}

export default ImageWithColorBackground